<template>
  <div>
    <div class="titre-container mb-0">
      <h1 class="mt-0 fs-b fs-2500 color-pr mb-0">Saisie de mon code</h1>
    </div>

    <div class="text-left">
      <a
        @click="goToGdr()"
        tabindex="0"
        class="fr-link p-0 color-pr fs-875 go-gdr-link mb-4"
        title="Lien Visualiser href=/gdr"
        ><i class="ri-arrow-left-line"></i>Retour à Mes Entreprises / Mes
        rôles</a
      >
    </div>

    <div class="d-flex mt-1 mb-4">
      <span>
        Valider votre demande de rôle d'<strong
          >Administrateur Général de l'Entreprise</strong
        >
        (AGE).
      </span>
    </div>
    <div class="fr-notice fr-notice--info" v-if="delaiDepasse">
      <div class="fr-container">
        <div class="fr-notice__body">
          <p class="fr-notice__title">
            Votre demande n'est plus valide. Cliquez
            <a
              href="#"
              @click="annulerDemandeImprimfip($event)"
              title="Réitérer ma demande"
              >ici</a
            >
            si vous souhaitez la réitérer.
          </p>
        </div>
      </div>
    </div>
    <div class="mb-4 d-flex justify-content-center row">
      <div class="bloc">
        <label class="text mr-5 mt-2 mb-2" for="code"
          >Saisir le code d'activation <br /><span class="text-info"
            >12 caractères</span
          ></label
        >
        <input
          id="code"
          class="col fr-input"
          v-model="codeActivation"
          maxlength="12"
          @keypress="checkCaractere($event)"
        />
      </div>
      <div class="d-flex justify-content-end w-50">
        <div class="bloc-btn w-50">
          <button
            class="btn-annuler"
            @click="annuler()"
            aria-label="Annuler la saisie"
          >
            Annuler
          </button>
          <button class="btn" @click="valider()" aria-label="Valider la saisie">
            Valider
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { EN_ATTENTE_CONFIRMATION_CODE } from '../constantes';

import { dateTimeFormatIso } from '@/filters';

export default {
  data() {
    return {
      codeActivation: '',
      demande: undefined,
      delaiDepasse: false,
      roleData: { siren: '', rs: '' }
    };
  },
  props: {},
  computed: {
    ...mapGetters(['getRouteData'])
  },
  mounted() {
    if (
      this.getRouteData &&
      this.getRouteData.roleData &&
      this.getRouteData.roleData.siren &&
      this.getRouteData.roleData.siren.length
    ) {
      this.roleData = this.getRouteData.roleData;
      this.checkAttributionEtat();
    } else {
      this.goToGdr();
    }
  },
  methods: {
    ...mapActions(['showPopup', 'setContent']),
    goToGdr() {
      this.$router.push({ name: 'Gestion des rôles' });
    },
    async checkAttributionEtat() {
      await this.$rolesService
        .getRoleAttribution(
          EN_ATTENTE_CONFIRMATION_CODE,
          this.roleData.idDemande
        )
        .then((result) => {
          if (result.data[0].isExpire === true) {
            this.delaiDepasse = true;
          } else if (result.status === 200) {
            let attributionRoleList = result.data;
            if (attributionRoleList && attributionRoleList.length > 0) {
              attributionRoleList = attributionRoleList.filter(
                (it) =>
                  it.siren === this.roleData.siren &&
                  it.etat === EN_ATTENTE_CONFIRMATION_CODE
              );

              if (attributionRoleList.length === 1) {
                this.demande = attributionRoleList[0];
              } else {
                this.goToGdr();
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
          this.goToGdr();
        });
    },
    valider() {
      let message = 'Le code est erronée, veuillez vérifier votre saisie.';
      if (this.codeActivation.length < 12) {
        this.showMessage(message);
      } else if (this.delaiDepasse === true) {
        message = 'Le code n’est plus valide (délai dépassé).';
        this.showMessage(message);
      } else {
        this.demande.code = this.codeActivation;
        this.demande.dateModification = dateTimeFormatIso(new Date());
        this.demande.idDemande = this.roleData.idDemande;
        this.$rolesService
          .sendCode(this.demande)
          .then((result) => {
            if (result.status === 200) {
              message = `Votre demande d'attribution du rôle AGE est confirmée.`;
              this.showMessage(message);
              this.goToGdr();
            }
          })
          .catch((err) => {
            if (err?.response?.status === 400) {
              message = err?.response?.data[0]?.message;
              this.showMessage(message);
            } else {
              message = 'Une erreur est survenue pendant le traitement.';
              this.showMessage(message);
            }
          });
      }
    },
    annulerDemandeImprimfip(event) {
      if (event) {
        event.preventDefault();
      }
      this.$rolesService
        .annulerImprimFipDemande(this.roleData.idDemande)
        .then(() => {
          this.goToGdr();
        })
        .catch((err) => {
          console.log(err);
          this.goToGdr();
        });
    },
    annuler() {
      const message = `<p class="fs-875">Êtes-vous sûr de vouloir abandonner ?</p>`;
      this.setContent({ html: message, action: 'confirm' });
      this.showPopup();
      this.emitter.once('popup', (payload) => {
        if (payload === 'oui') {
          this.goToGdr();
        }
      });
    },
    showMessage(message) {
      const html = `<p class="fs-875"> ${message}</p>`;
      this.setContent({ html: html, action: 'info' });
      this.showPopup();
    },
    checkCaractere(event) {
      var regex = new RegExp('^[a-zA-Z0-9 ]+$');
      var codeActivation = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      );
      if (regex.test(codeActivation)) {
        return true;
      }
      event.preventDefault();
      return false;
    }
  }
};
</script>
<style lang="scss" scoped>
.fr-link {
  cursor: pointer !important;
}

.go-gdr-link {
  text-decoration: underline !important;
  text-underline-offset: 4px;
}

.go-gdr-link:hover {
  text-decoration: underline 1.5px !important;
}

.btn-annuler {
  background: #ffffff;
  color: $base-color;
  border: $base-color solid 1px;
  font-size: 14px;
  width: 100%;
  margin-left: 4rem;
  margin-right: 1rem;
  height: 2.5rem;
}

.btn {
  background: $base-color;
  color: white;
  font-size: 14px;
  border: 0;
  width: 100%;
  margin-left: 1rem;
  height: 2.5rem;
}

.btn:hover {
  background-color: #1212ff;
}

.btn:disabled {
  background-color: #e7e7e7 !important;
  color: #6a6a6a !important;
  cursor: not-allowed !important;
  pointer-events: unset;
}

.bloc {
  width: 50%;
  display: block;
  padding: 1rem;
}

.bloc-btn {
  width: auto;
  display: flex;
  padding: 1rem;
}

.fr-input {
  box-shadow: inset 0 -2px 0 0 #000091;
}

.text {
  color: #000000;
}

.text-info {
  color: #6a6a6a !important;
  font-size: 13px;
}
.fr-notice__title a {
  color: #0063cb;
}

@media only screen and (max-width: 760px) {
  .container-margin {
    margin: unset;
    margin-top: 2rem;
  }

  .titre-container {
    height: unset;
    margin-bottom: 0.5rem !important;
  }

  .fs-2500 {
    font-size: 2rem !important;
  }

  .bloc {
    width: 100%;
  }

  .text {
    font-size: 15px;
    margin-bottom: 0.5rem !important;
    margin-right: unset !important;
  }

  .bloc-btn {
    width: 100% !important;
  }
}
</style>
